import {createSlice} from '@reduxjs/toolkit';
import {AppState} from '../../mappers/app';


const appState: AppState = {
    alert: {type: '', message: ''},
    showAlert: false,
    // loading: false,
    // reload: false,
    pendingCount:[],
    UserId:[],
    branchName:"",
    RoleName:""
};


const AppSlice = createSlice({
    name: 'app',
    initialState: appState,
    reducers: {
        showAlert(state, {payload}) {
            state.showAlert = true;
            state.alert.type = payload.type;
            state.alert.message = payload.message;
        },
        hideAlert(state) {
            state.showAlert = false;
            state.alert.type = '';
            state.alert.message = '';
        },
        setLoading(state,{payload}){
            state.value = payload
        },
        SetPending(state,{payload}){
            state.pendingCount = payload
        },
        SetBranch(state,{payload}){
            state.branchName = payload
        },
        SetUserId(state,{payload}){
            state.UserId = payload
        },
        SetRoleName(state,{payload}){
            state.RoleName = payload
        },
        resetState() {
            return appState; // Reset the state to the initial state
        }
    },
});

export const {
    // startLoading,
    // stopLoading,
    hideAlert,
    showAlert,
    // reload,
    SetRoleName,
    SetBranch,
    SetUserId,
    setLoading,
    SetPending,
    resetState
} = AppSlice.actions;
export default AppSlice.reducer;
