export const PATHS = {
  login: "/",
  Otp: "/Otp",
  ResetPassword: "/ResetPassword'",
  ChangePassword:"/ChangePassword",
  ResetChangePassword:"/ResetChangePassword",
  ForgotPassword:"/ForgotPassword",
 
  User:'/users',
  UsersEdit:'/userEdit',
  Dashboard: "/dashboard",
  Passportlinechart:"/passportlinechart",
  Dashboard3:"/combineDashBoard",
  IdentityRecords:"/identityrecords",
  IdentityRecordsView:"/identityrecordsview",
  Transactions:"/Transactions",
  Kyc:"/Kyc",
  Auditdashboard:"/auditdashboard",
  Roles:"/roles",
  Roleview:"/Roleview",
  userManagement: {
    root: "/userManagement",
  },
  Audit: "/audit",
  Management: {
    root: "/management",
    pending:"/pending",
    completed:"/completed",
    Cancel:"/Cancel"
  },
 
  ManagementView:"managementView",

  VerficationView:"/verificationView",
  ValidityChecks:"/validityChecks",
  VarificationCheckResult:"/varificationCheckResult",
  Metrcis:"/metrcis",
  Linechat:"/linechatview",
  Linechatpassport:"linechartpassport",

  Auditeventmetrics:"/Auditeventmetrics",
  Auditdata:"Auditdata",

  Reportusers:"Reportusers",
  Reportkyc:"ReportKyc",
  Reporteidcard:"Reporteidcard"
 
};

export const SM_WIDTH = 1200;


